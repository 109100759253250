@font-face {
	font-family: "Quicksand";
	font-weight: normal;
	font-style: normal;
	src: url("../../src/font/quicksand/Quicksand-Regular.otf") format("opentype");
}

@font-face {
	font-family: "Quicksand";
	font-weight: bold;
	font-style: normal;
	src: url("../../src/font/quicksand/Quicksand-Bold.otf") format("opentype");
}

@font-face {
	font-family: "Wouahou";
	font-weight: bold;
	font-style: normal;
	src: url("../../src/font/womby/Womby-Regular.ttf") format("truetype");
}

body {
	font-family: "Quicksand", Verdana, Geneva, sans-serif;
	font-size: 18px;
	color: var(--text-color);
}

.font-wouahou {
	font-family: "Wouahou", "Quicksand", Verdana, Geneva, sans-serif;
}

h1,
h2,
h3,
h4 {
	font-family: "Wouahou", "Quicksand", Verdana, Geneva, sans-serif;
	text-transform: uppercase;
	hyphens: auto;
}

@media (min-width: 48rem) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h1 a,
	h2 a,
	h3 a,
	h4 a,
	h5 a,
	h6 a,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		hyphens: none;
	}
}

a {
	color: var(--text-color);
}

a:hover {
	transition: all .2s cubic-bezier(.2,1,.22,1);
	color: var(--color-red);
}

a:focus,
button:focus,
input:focus {
	outline: none;
}

.leading-loose {
	line-height: 1.5;
}

.content__text p {
	margin-bottom: 16px;
}

.content__text ul,
.wouahou-list {
	padding-left: 20px;
	list-style: none;
	margin: 30px 0;
}

.content__text li,
.wouahou-list li {
	font-weight: bold;
	color: #ffffff;
	padding: 2px 4px;
	position: relative;
	display: table;
	margin: 0 auto;
	margin-bottom: 8px;
	background: var(--color-yellow);
}

.text-big {
	font-size: 100px;
}

:root {
	--text-color: #030303;
}
.btn {
	text-decoration: none;
	font-family: "Wouahou", sans-serif;
	padding: 8px 20px;
	border-radius: 50px;
	letter-spacing: 1px;
}

.btn.bg-red:hover,
.btn.bg-green:hover {
	color: var(--color-yellow);
}
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
}

.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 .5rem;
	box-sizing: border-box;
}

.mfp-container:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	user-select: none;
}

.mfp-close {
	width: 2.75rem;
	height: 2.75rem;
	line-height: 2.75rem;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 1.125rem .625rem;
	color: #fff;
	font-style: normal;
	font-size: 1.75rem;
	font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
	opacity: 1;
}

.mfp-close:active {
	top: .0625rem;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: #fff;
	right: -.375rem;
	text-align: right;
	padding-right: .375rem;
	width: 100%;
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-iframe-holder .mfp-close {
	top: -2.5rem;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}


.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #ccc;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8rem;
	left: .5rem;
	right: .5rem;
	z-index: 1044;
}

.mfp-preloader a {
	color: #ccc;
}

.mfp-preloader a:hover {
	color: #fff;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #ccc;
	font-size: .75rem;
	line-height: 1.125rem;
	white-space: nowrap;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	margin: 0;
	top: 50%;
	margin-top: -3.4375rem;
	padding: 0;
	width: 5.625rem;
	height: 6.875rem;
	-webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
	margin-top: -3.375rem;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 2.1875rem;
	margin-left: 2.1875rem;
	border: medium inset transparent;
}

.mfp-arrow:after {
	border-top-width: .8125rem;
	border-bottom-width: .8125rem;
	top: .5rem;
}

.mfp-arrow:before {
	border-top-width: 1.3125rem;
	border-bottom-width: 1.3125rem;
	opacity: 0.7;
}

.mfp-arrow-left {
	left: 0;
}

.mfp-arrow-left:after {
	border-right: 1.0625rem solid #fff;
	margin-left: 1.9375rem;
}

.mfp-arrow-left:before {
	margin-left: 1.5625rem;
	border-right: 1.6875rem solid #3f3f3f;
}

.mfp-arrow-right {
	right: 0;
}

.mfp-arrow-right:after {
	border-left: 1.0625rem solid #fff;
	margin-left: 2.4375rem;
}

.mfp-arrow-right:before {
	border-left: 1.6875rem solid #3f3f3f;
}

.mfp-iframe-holder {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 56.25rem;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.6);
	background: #000;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 2.5rem 0;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}

.mfp-figure:after {
	content: "";
	position: absolute;
	left: 0;
	top: 2.5rem;
	bottom: 2.5rem;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.6);
	background: #444;
}

.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: .75rem;
	line-height: .875rem;
}

.mfp-figure figure {
	margin: 0;
}

.mfp-bottom-bar {
	margin-top: -2.25rem;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 1.125rem;
	color: #f3f3f3;
	word-wrap: break-word;
	padding-right: 2.25rem;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 50rem) and (orientation: landscape), screen and (max-height: 18.75rem) {
	/**
		 * Remove all paddings around the image on small screen
		 */
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}

	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}

	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}

	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: .3125rem;
	}

	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: .1875rem .3125rem;
		position: fixed;
		box-sizing: border-box;
	}

	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}

	.mfp-img-mobile .mfp-counter {
		right: .3125rem;
		top: .1875rem;
	}

	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 2.1875rem;
		height: 2.1875rem;
		line-height: 2.1875rem;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}

@media all and (max-width: 56.25rem) {
	.mfp-arrow {
		transform: scale(0.75);
	}

	.mfp-arrow-left {
		transform-origin: 0;
	}

	.mfp-arrow-right {
		transform-origin: 100%;
	}

	.mfp-container {
		padding-left: .375rem;
		padding-right: .375rem;
	}
}

/* overlay at start */
.mfp-fade.mfp-bg {
	opacity: 0;
	transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}

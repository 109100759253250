.bg-yellow {
	background-color: var(--color-yellow);
}

.bg-red {
	background-color: var(--color-red);
}

.bg-green {
	background-color: var(--color-green);
}

.bg-grey {
	background-color: var(--color-grey);
}

.color-yellow {
	color: var(--color-yellow);
}

.color-yellow-hover:hover,
.color-yellow-hover:focus {
	color: var(--color-yellow);
}

.color-red {
	color: var(--color-red);
}

.color-red-hover:hover,
.color-red-hover:focus {
	color: var(--color-red);
}

.color-green {
	color: var(--color-green);
}

.color-green-hover:hover,
.color-green-hover:focus {
	color: var(--color-green);
}

.color-grey {
	color: var(--color-grey);
}

.color-grey-hover:hover,
.color-grey-hover:focus {
	color: var(--color-grey);
}

.color-white-hover:hover,
.color-white-hover:focus {
	color: #ffffff;
}

.bg-insta {
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285aeb 90%);
}
.bg-sbahn {
	background: #53b330;
}

:root {
	--color-yellow: #fcbd41;
	--color-red: #7f2845;
	--color-green: #005357;
	--color-grey: #3e3e3e;
}
.controls__element {
	min-height: 12rem;
}

.controls__element:after {
	background-color: rgba(13,13,13,0.35);
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	display: block;
	z-index: 1;
	width: 100%;
	height: 100%;
	transition: background-color 0.4s cubic-bezier(0.05, 0.2, 0.1, 1) 0s;
}

.controls__element:hover:after {
	background-color: rgba(13,13,13,0.5);
}

.controls__image {
	transition: transform 0.4s cubic-bezier(0.05, 0.2, 0.1, 1) 0s;
}

.controls__text {
	color: #fff;
	font-weight: normal;
}

.controls__element:hover .controls__image {
	transform: scale(1.1);
}

.controls__arrow--right {
	animation: transform 0.4s cubic-bezier(0.05, 0.2, 0.1, 1) 0s;
}

.controls__element:hover .controls__arrow--right {
	transform: translateX(.25rem);
}

.controls__element--disabled {
	background: #fff;
}

.controls__element--disabled:after,
.controls__element--disabled:hover:after {
	background: none;
}
.music__link--facebook i,
.music__link--instagram i {
	font-size: 19px;
}

.music__link--soundcloud i,
.music__link--website i,
.music__link--youtube i,
.music__link--facebook i,
.music__link--instagram i {
	top: 1px;
}

.music__link--website:hover i {
	color: var(--color-red);
}

.music__link--facebook:hover i {
	color: #3b5998;
}

.music__link--instagram:hover i {
	color: #bc2a8d;
}

.music__link--soundcloud:hover i {
	color: #ff8800;
}

.music__link--youtube:hover i {
	color: #ff0000;
}

.music__cal {
	top: -2px;
}

.music__embed iframe {
	width: 100% !important;
	max-width: 100% !important;
}
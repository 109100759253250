.story__baum1 {
	left: 50%;
}

.story__baum2 {
	left: 55%;
	bottom: -50px;
}

.story__mond {
	right: 3%;
	top: 50px;
}

.story__schnee {
	right: 10%;
	top: -10px;
	width: 20%;
}

.story__feder1 {
	left: 27%;
	top: 20%;
}

.story__feder2 {
	right: 0;
	top: 20%;
}

.story__feder3 {
	left: 34%;
	top: 60%;
}
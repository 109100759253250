main {
	margin-top: 106px;
	background: #ffffff;
}

@media screen and (min-width: 768px) {
	main {
		margin-top: 161px;
	}
}


@media screen and (min-width: 1200px) {
	main {
		margin-top: 165px;
	}
}

.card {
	flex: 0 1 auto;
	background: var(--color-grey);
	min-height: 150px;
}

.card:hover .card__asset {
	filter: sepia(1);
}

.card:nth-child(2n+1) {
	background: var(--color-grey);
}

.card:nth-child(3n+2) {
	background: var(--color-red);
}

.card:nth-child(5n+2) {
	background: var(--color-green);
}

.card:nth-child(3n+0) {
	background: var(--color-yellow);
}

.card:nth-child(4) {
	background: var(--color-grey);
}


.animated {
	transition: all .7s cubic-bezier(.2,1,.22,1);
}

/* ################## BIGGER PADDING CLASSES ################## */
.pt-sm {
	padding-top: 1rem;
}

@media (min-width: 48rem) {
	.pt-sm {
		padding-top: 2rem;
	}
}

.pt-md {
	padding-top: 2rem;
}

@media (min-width: 48rem) {
	.pt-md {
		padding-top: 4rem;
	}
}

.pt-lg {
	padding-top: 4rem;
}

@media (min-width: 48rem) {
	.pt-lg {
		padding-top: 8rem;
	}
}

.pr-sm {
	padding-right: 2rem;
}

@media (min-width: 48rem) {
	.pr-sm {
		padding-right: 1rem;
	}
}

.pr-md {
	padding-right: 2rem;
}

@media (min-width: 48rem) {
	.pr-md {
		padding-right: 4rem;
	}
}

.pr-lg {
	padding-right: 4rem;
}

@media (min-width: 48rem) {
	.pr-lg {
		padding-right: 8rem;
	}
}

.pb-sm {
	padding-bottom: 1rem;
}

@media (min-width: 48rem) {
	.pb-sm {
		padding-bottom: 2rem;
	}
}

.pb-md {
	padding-bottom: 2rem;
}

@media (min-width: 48rem) {
	.pb-md {
		padding-bottom: 4rem;
	}
}

.pb-lg {
	padding-bottom: 4rem;
}

@media (min-width: 48rem) {
	.pb-lg {
		padding-bottom: 8rem;
	}
}

.pl-sm {
	padding-left: 1rem;
}

@media (min-width: 48rem) {
	.pl-sm {
		padding-left: 2rem;
	}
}

.pl-md {
	padding-left: 2rem;
}

@media (min-width: 48rem) {
	.pl-md {
		padding-left: 4rem;
	}
}

.pl-lg {
	padding-left: 4rem;
}

@media (min-width: 48rem) {
	.pl-lg {
		padding-left: 8rem;
	}
}

.mt-sm {
	margin-top: 1rem;
}

@media (min-width: 48rem) {
	.mt-sm {
		margin-top: 2rem;
	}
}

.mt-md {
	margin-top: 2rem;
}

@media (min-width: 48rem) {
	.mt-md {
		margin-top: 4rem;
	}
}

.mt-lg {
	margin-top: 4rem;
}

@media (min-width: 48rem) {
	.mt-lg {
		margin-top: 8rem;
	}
}

.mb-sm {
	margin-bottom: 1rem;
}

@media (min-width: 48rem) {
	.mb-sm {
		margin-bottom: 2rem;
	}
}

.mb-md {
	margin-bottom: 2rem;
}

@media (min-width: 48rem) {
	.mb-md {
		margin-bottom: 4rem;
	}
}

.mb-lg {
	margin-bottom: 4rem;
}

@media (min-width: 48rem) {
	.mb-lg {
		margin-bottom: 8rem;
	}
}
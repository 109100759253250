.main-slider {
	position: relative;
	width: 100%;
	height: 65vh;
	min-height: 8vw;
	opacity: 0;
	visibility: hidden;
	transition: all 1.2s ease;
}

.main-slider:not(.home) {
	margin-bottom: 100px;
}

@media screen and (min-width: 992px) {
	.main-slider {
		height: 30vw;
	}
}

.main-slider.slick-initialized {
	opacity: 1;
	visibility: visible;
}

.main-slider .slick-slide {
	position: relative;
	height: 65vh;
	backface-visibility: hidden;
}

@media screen and (min-width: 992px) {
	.main-slider .slick-slide {
		height: 30vw;
	}
}

/*.slick-slide:before {*/
	/*content: "";*/
	/*position: absolute;*/
	/*top: 0;*/
	/*left: 0;*/
	/*width: 100%;*/
	/*height: 100%;*/
	/*backface-visibility: hidden;*/
	/*background-color: #000000;*/
	/*opacity: .175;*/
	/*z-index: 1;*/
/*}*/

.slick-slide video {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	transform: translate(-50%, -50%);
}

.slick-slide.video iframe {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	transform: translate(-50%, -50%);
}

.slick-slide iframe {
	position: relative;
	pointer-events: none;
}

.slick-slide figure {
	position: relative;
	height: 100%;
}

.slick-slide .slide-image {
	opacity: 0;
	height: 100%;
	background-size: cover;
	background-position: center;
	transition: all .8s ease;
}

.slick-slide .slide-image.lazyloaded {
	opacity: 1;
}

.slick-slide .image-entity {
	width: 100%;
	opacity: 0;
	visibility: hidden;
}

.slick-slide .loading {
	position: absolute;
	top: 44%;
	left: 0;
	width: 100%;
}

.slick-slide.slick-active {
	z-index: 1;
}

.slick-slide.slick-active .caption {
	opacity: 1;
	transform: translateY(0);
	transition: all .7s cubic-bezier(0.32, 0.34, 0, 1.62) .6s;
}

.caption {
	text-align: left;
	margin: 0;
	opacity: 0;
	z-index: 1;
	transition: all .3s ease;
	transform: translateY(100px);
	backface-visibility: hidden;
}

@media screen and (min-width: 991px) {
	.caption {
		top: 30%;
	}
}

.caption p {
	max-width: 400px;
}

.caption h2 {
	font-size: 60px;
}

@media screen and (min-width: 991px) {
	.caption h2 {
		font-size: 100px;
	}
}

@media screen and (min-width: 1200px) {
	.caption h2 {
		font-size: 100px;
	}
}

.main-slider .slick-arrow {
	position: absolute;
	top: 50%;
	width: 30px;
	height: 50px;
	padding: 0;
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
	z-index: 10;
	display: none;
}

@media screen and (min-width: 1200px) {
	.main-slider .slick-arrow {
		display: block;
	}
}

.main-slider .slick-arrow:before {
	font-family: "Font Awesome 5 Solid";
	font-size: 50px;
	line-height: 1;
	opacity: .75;
	color: #ffffff;
}

.main-slider .slick-prev {
	left: 16px;
}

.main-slider .slick-prev:before {
	content: "\f053";
}

.main-slider .slick-next {
	right: 16px;
}

.main-slider .slick-next:before {
	content: "\f054";
}

.main-slider .slick-dots {
	text-align: center;
	padding: 15px 0;
	margin-top: 0;
	background: var(--color-yellow);
}

.main-slider.home .slick-dots {
	display: none !important;
}

.main-slider .slick-dots li {
	display: inline-block;
	vertical-align: top;
	margin: 0 8px;
}

.main-slider .slick-dots button {
	width: 16px;
	height: 16px;
	cursor: pointer;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0 0 0 transparent;
	vertical-align: middle;
	color: #fff;
	background-color: #fff;
	transition: all .3s ease;
	opacity: .4;
	text-indent: -9999px;
}

.main-slider .slick-dots button:focus {
	outline: none;
}

.main-slider .slick-dots button:hover {
	opacity: 1;
}

.main-slider .slick-dots li.slick-active button {
	border-color: var(--color-yellow);
	box-shadow: 0 0 0 2px #fff;
	opacity: 1;
}

.main-slider.maskenball:before {
	content: "";
	background-image: url("/src/img/maskenball-big.svg");
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}
@media screen and (min-width: 992px) {
	.countdown-time .text-4xl {
		font-size: 60px;
	}
}

@media screen and (min-width: 1200px) {
	.countdown-time .text-4xl {
		font-size: 80px;
	}
}

.countdown__logo {
	padding: 40px 0;
}

.countdown__logo img {
	max-width: 50%;
}

.countdown__traumbig {
	right: 60px;
}

.countdown__moon {
	right: 140px;
	top: 40px;
}

.countdown__snowleft {
	width: 19%;
	top: 60px;
}

.countdown__snowright {
	width: 19%;
	top: 30%;
	right: 50px;
}

.countdown__tipisleft {
	left: 7%;
	top: 40%;
}

.countdown__fire {
	right: 50px;
	bottom: 28%;
}
.navigation__element a:hover,
.topmenu:hover {
	color: var(--color-yellow);
}

.header__free {
	width: 87px;
	right: 27%;
}

.header__tipis {
	right: 5%;
	width: 90px;
	bottom: -30px;
	z-index: 2;
}

.winterdorf__sueden {
	left: 50%;
}

@media screen and (min-width: 1200px){
	.winterdorf__sueden {
		left: 32%;
	}
}
/**
 * GENERIC STYLES
 *
 * Bringing browsers of all kinds to nearly the same level
 */

@import "normalize.css";
@import "video.js/dist/video-js.css";
@import "tailwindcss/dist/tailwind.css";
@import "slick-carousel/slick/slick.css";
@import "jquery.mmenu/dist/jquery.mmenu.css";
@import "jquery.mmenu/dist/extensions/positioning/jquery.mmenu.positioning.css";


/**
 * BASE STYLES
 *
 * Base styling for elements like the body font family etc.
 */
@import "_base.animate";
@import "_base.colors";
@import "_base.typo";
@import "_base.page";
@import "_base.popup";
@import "_base.font-awesome";

/**
 * OBJECTS
 *
 */

@import "_object.button";
@import "_object.controls";
@import "_object.form";
@import "_object.dropdown";

/**
 * MODULES
 *
 */

@import "_module.article";
@import "_module.countdown";
@import "_module.slick";
@import "_module.page";
@import "_module.header";
@import "_module.story";
@import "_module.index";
@import "_module.footer";
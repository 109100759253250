.index__box p {
	font-size: 1rem;
	line-height: 1.35;
}

.flex--vertical {
	flex-direction: row;
	flex-wrap: wrap;
}

.mini-slick .slick-arrow {
	position: absolute;
	z-index: 10;
	font-size: 0;
	line-height: 0;
	color: #ffffff;
	top: 50%;
}

.mini-slick .slick-arrow:before {
	font-family: "Font Awesome 5 Solid";
	font-size: 50px;
}

.mini-slick .slick-prev {
	left: 16px;
}

.mini-slick .slick-next {
	right: 16px;
}

.mini-slick .slick-prev:before {
	content: "\f053";
}

.mini-slick .slick-next:before {
	content: "\f054";
}

.mini-slick .slick-slide {
	height: inherit;
}

.index-exhib__border {
	border: 5px solid var(--color-yellow);
	height: 55vh;
	min-height: 450px;
}

.index-exhib__title {
	font-family: "Wouahou", sans-serif;
	font-size: 50px;
}

@media screen and (min-width: 768px) {
	.index-exhib__title {
		font-size: 80px;
	}
}

.index-exhib__dreamleft {
	left: 10%;
	width: 60px;
}

.index-exhib__dreamright {
	right: 12%;
	width: 100px;
}

.index__box--video iframe {
	left: -70%;
	top: -30%;
}

.index-info__left {
	width: 210px;
	left: 30px;
	top: 20%;
}

.index-info__right {
	width: 160px;
	right: 96px;
	bottom: 10%;
}

.index-video__mask {
	background-repeat: no-repeat;
	background-size: cover;
}

.index__insta {
	min-height: 250px;
}

.index__insta .bg-insta {
	opacity: 1;
}

@media screen and (min-width: 992px) {
	.index__insta .bg-insta {
		opacity: 0;
	}
}

.index__insta:hover .bg-insta {
	opacity: 1;
}

.index__insta i {
	font-size: 100px;
	margin-top: 30px;
}

.index__insta__bg {
	background-size: cover;
	background-repeat: no-repeat;
}